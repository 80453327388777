import React, { useEffect, useState } from 'react';
import './App.css'; // 스타일 파일을 불러옵니다.
import logo from './logo.png';
import image1 from './images/ardu1.jpg';
import image2 from './images/ardu2.jpg';
import image3 from './images/ardu3.jpg';
import image4 from './images/ardu4.jpg';
import image5 from './images/ardu5.jpg';
import image6 from './images/ardu6.jpg';
import project1 from './images/project1.webp'
import project2 from './images/project2.webp'
import project3 from './images/project3.webp'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [introVisible, setIntroVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [teamVisible, setTeamVisible] = useState(false);
  const [projectsVisible, setProjectsVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) { // 각 섹션의 시작 위치에 따라 조절
      setIntroVisible(true);
    }
    if (window.scrollY > 200) {
      setAboutVisible(true);
    }
    if (window.scrollY > 400) {
      setTeamVisible(true);
    }
    if (window.scrollY > 600) {
      setProjectsVisible(true);
    }
    if (window.scrollY > 800) {
      setContactVisible(true);
    }
  };

  const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 1,
        cssEase: "linear"
    };

  return (
    <div className="App">
      <header>
        <img src={logo} alt="로고" width='150px' />
      </header>

      <main>
        <section className={`intro ${introVisible ? 'visible' : ''}`}>
          <div className="intro-content">
            <h1>IoT 교육 사업</h1>
            <p>다양한 장치들을 배워볼 수 있어요.</p>
            <div className='sliderContainer'>
              <Slider {...settings}>
                <div>
                  <img src={image1} alt="Image 1" />
                </div>
                <div>
                  <img src={image2} alt="Image 2" />
                </div>
                <div>
                  <img src={image3} alt="Image 3" />
                </div>
                <div>
                  <img src={image4} alt="Image 4" />
                </div>
                <div>
                  <img src={image5} alt="Image 5" />
                </div>
                <div>
                  <img src={image6} alt="Image 6" />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section className={`about ${aboutVisible ? 'visible' : ''}`}>
          <div className="about-content">
            <h2>SSTR(SangSangToReal)</h2>
            <p>상상을 현실로 만듭니다.</p>
            <p>여러분이 상상했던 모든 것을 만들어 보세요.</p>
          </div>
        </section>

        <section className={`team ${teamVisible ? 'visible' : ''}`}>
          <div className="team-content">
            <h2>Who we Are?</h2>
            <p>국내 최초 4시간 방탈출 제작팀</p>
            <p>아두이노, 마이크로비트, esp32 등 개발보드를 활용한</p>
            <p>시제품 제작 프로젝트 진행</p>
          </div>
        </section>

        <section className={`projects ${projectsVisible ? 'visible' : ''}`}>
          <div className="projects-content">
            <h2>What we`ve done?</h2>
            <div className='sliderContainer'>
              <Slider {...settings}>
                <div>
                  <img src={project1} alt="Project 1" />
                  <p className='caption'>스마트 쓰레기통</p>
                </div>
                <div>
                  <img src={project2} alt="Project 2" />
                  <p className='caption'>마이크로 비트 드로잉 봇</p>
                </div>
                <div>
                  <img src={project3} alt="Project 3" />
                  <p className='caption'>IOT 솔레노이드 락</p>
                </div>
                <div>
                  <img src={image4} alt="Image 4" />
                </div>
                <div>
                  <img src={image5} alt="Image 5" />
                </div>
                <div>
                  <img src={image6} alt="Image 6" />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section className={`contact ${contactVisible ? 'visible' : ''}`}>
          <div className="contact-content">
            <h2>CONTACT</h2>
            <a href="https://open.kakao.com/o/sEVv8Fkg" class="button">교육 문의</a>
            <a href="https://open.kakao.com/o/sY0QHmig" class="button">외주 문의</a>
            <p>카카오톡 문의로 연결됩니다.</p>
          </div>
        </section>
      </main>

      <footer>
        <p>&copy; 주식회사 바라봄</p>
      </footer>
    </div>
  );
}

export default App;
